<template>
	<section class="forgot-password-popup global-popup">
		<div class="container">
			<div class="column-wrapper">
				<div class="column form">
					<div class="title-wrapper">
						<h2 class="column-title">Forgot password?</h2>
						<p class="subtitle">
							Enter your e-mail to receive an reset link.
						</p>
					</div>

					<ForgotPasswordForm />
					<button class="back-to-login" @click="showLoginPopup">
						Back to login
					</button>
					<button class="close-btn" @click="hidePopups"></button>
				</div>
			</div>
		</div>

		<div class="background-el" @click="hidePopups"></div>
	</section>
</template>

<script>
// @import component
import ForgotPasswordForm from "@/components/Forms/ForgotPasswordForm";

export default {
	name: "ForgotPasswordFormPopup",
	components: {
		ForgotPasswordForm,
	},
	methods: {
		showLoginPopup() {
			this.$store.commit("showLoginPopup");
			this.$store.commit("hidePasswordPopup");
		},
		hidePopups() {
			this.$store.commit("hideLoginPopup");
			this.$store.commit("hidePasswordPopup");
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
