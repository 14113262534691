<template>
    <form @submit.prevent="submitForm">
        <div v-if="showError" class="error-msg">
            <p>{{ errorMsg }}</p>
        </div>
        <div v-if="showSucces" class="succes-msg">
            <p>{{ succesMsg }}</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-forgot-password">E-mail</label>
                <div class="input-wrapper">
                    <input id="user-forgot-password" name="user-forgot-password" type="email" placeholder="E-mail"
                        v-model="currentUsername" />
                </div>
            </span>
        </div>

        <button class="submit" data-txt="Submit">Submit</button>
    </form>
</template>

<script>
import { forgotPassword } from "@streampac.io/chef_sp_1";

export default {
    name: "forgotPasswordForm",
    inject: ["isAuthenticated"],
    data() {
        return {
            currentUsername: "",
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",
        };
    },
    methods: {
        submitForm() {
            if (this.currentUsername !== "") {
                const postObj = "?userName=" + encodeURIComponent(this.currentUsername);
                forgotPassword(this.isAuthenticated, postObj).then(
                    (response) => {
                        if (response) {
                            this.showSucces = true;
                            this.showError = false;
                            this.succesMsg = "An e-mail has been send";

                            //Remove the popup
                            setTimeout(() => {
                                this.$store.commit("showLoginPopup");
                                this.$store.commit("hidePasswordPopup");
                            }, 3000);
                        } else {
                            this.showError = true;
                            this.showSucces = false;
                            this.errorMsg = "Something went wrong..";
                        }
                    }
                );
            }
        },
    },
};
</script>
